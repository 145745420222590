.screen-section {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 1.5rem;
    background-color: var(--white_background);
  }
  
  .screen-right {
    h6:not(:first-child) {
      color: var(--grey_one);
    }
  }
  
  .preview-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    // background-image: url('../../assets/images/screen-img.png');
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
  }
  