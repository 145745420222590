.comment-textbox {
  display: flex;
  margin-block: 5%;
}

.comment-input {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

// .comment-button {
//   margin-left: 10px;
//   padding: 8px 16px;
//   background-color: #007bff;
//   color: #fff;
//   border: none;
//   border-radius: 4px;
//   cursor: pointer;
// }

// .comment-button:hover {
//   background-color: #0056b3;
// }

.ticket-details {
    background-color: var(--white_background);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 1.5rem;
    .custom-header h6 {
      font-weight: bold;
    }
    h6 {
      line-height: 1.6rem;
    }
  }
  .table th{
    text-align: left;
    }
  .table td{
    text-align: left;
    }   
  .ticketDesc{
    margin-left: 20px;
  }
  .left-side{
    width: 2rem;
  }
  .right-side{
    width: 5rem;
  }
  .card-wide{
    width: 600px;
  }
  .edit-schedule {
    background-color: var(--primary_light_color);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    span {
      color: var(--primary_color);
      font-size: 24px;
    }
  }
  .screen-types {
    .btn-white-bg {
      background-color: var(--white_background);
      color: var(--text);
      font-weight: bold;
      border: none;
      padding: 0.35rem 1.5rem;
      text-align: center;
      border-radius: 4px;
      border: 1px solid var(--grey_five);
      &:hover {
        background-color: var(--primary_color);
        color: white;
        transition: all 300ms linear;
      }
    }
  }
  
  .input-range__label--max,
  .input-range__label--min {
    display: none !important;
  }
  .input-range__track--active {
    color: var(--primary_color) !important;
    background-color: var(--primary_color) !important;
  }
  .input-range__slider {
    background-color: var(--primary_color) !important;
    color: var(--primary_color) !important;
    border: none !important;
  }
  .image-preview {
    margin-bottom: 105px;
    margin-block: 7%;
    display: grid;
    grid-template-columns: repeat(3, 0.1fr);
    // grid-template-rows: repeat(2, 0.4fr);
    grid-gap: 40px;
    
  }
  
  .image-preview img {
    
    width: 380px;
    height: 343px;
    // display: block;
}

  .image-container {
    position: relative;
    display: inline-block;
  }
  
  // .ticket-image {
  //   // width: 100px; /* Set the initial size of the image */
  //   // height: 100px;
  //   transition: transform 0.3s ease-in-out;
  // }
  
  .ticket-image:hover {
    transform: scale(1.05); /* Increase the size of the image on hover */
  }
  
  .preview-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 16px;
    background-color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .image-container:hover .preview-button {
    opacity: 1; /* Show the preview button on hover */
  }
  .show_less{
    cursor: pointer;
    color: red;
  }
.myloader{
  width: 70px !important;
  height: 71px !important;
  margin-left: 30rem;

}