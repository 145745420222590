.comment {
    background-color: #e2e2e2;
    border-radius: 20px;
    padding: 10px;
    margin-bottom: 15px;
    display: flex; /* Add flex display to align the icon and content */
    box-shadow: 1.5px 3px #bfbfbf;
  }
  
  .comment-user-icon {
    width: 32px; /* Adjust the width and height as per your icon size */
    height: 32px;
    margin-right: 10px; /* Add margin to create spacing between the icon and content */
    display: flex; /* Ensure the icon is vertically centered */
    background-color: #A0C49D;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
  }
  
  .comment-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .comment-author {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .comment-edit-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    // resize: vertical;
    margin-bottom: 10px;
  }
  .comment-button {
    padding: 20px 16px;
    background-color: var(--primary_color);
    font-weight: bold;
    color: #fff;
    height: 60%;
    margin-block: auto;
    border: none;
    margin-top:3.4% ;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 2%;
  }
  .comment-button:hover {
    background-color: var(--primary_dark_color);
  }
  .comment-text {
    color: #333333;
    text-align: justify;
  }