.list-group-item {
  background-color: var(--white_background) !important;
  height: 3rem !important;
  color: var(--text) !important;
  border-radius: 8px !important;
  margin: 0.4rem 0 !important;
  border: none !important;
  display: flex !important;
  justify-content: flex-start !important;
  align-items: center !important;
  padding: 1.9rem 2rem !important;
  transition: background-color 500ms ease;
}

.list-group-item.active {
  z-index: 2;
  color: var(--primary_color) !important;
  background-color: var(--primary_light_color) !important;
  border: none;
  font-weight: bold;
  fill: var(--primary_color) !important;
}

.list-group-item:hover {
  background-color: var(--primary_light_color) !important;
  color: var(--primary_dark_color) !important;
  font-weight: bold;
  fill: var(--primary_dark_color) !important;
}
