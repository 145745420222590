.font_icon{
    position: absolute;
    right: 10px;
    top: 27px;
    transform: translateY(-50%);
    cursor: pointer;
}
.company_logo{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
