.store-details {
  background-color: var(--white_background);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 1.5rem;

  .custom-header h6 {
    font-weight: bold;
  }

  h6 {
    line-height: 1.6rem;
  }
}

.edit-schedule {
  background-color: var(--primary_light_color);
  padding: 0.5rem 1rem;
  border-radius: 4px;

  span {
    color: var(--primary_color);
    font-size: 24px;
  }
}

.screen-types {
  .btn-white-bg {
    background-color: var(--white_background);
    color: var(--text);
    font-weight: bold;
    border: none;
    padding: 0.35rem 1.5rem;
    text-align: center;
    border-radius: 4px;
    border: 1px solid var(--grey_five);

    &:hover {
      background-color: var(--primary_color);
      color: white;
      transition: all 300ms linear;
    }
  }
}

.input-range__label--max,
.input-range__label--min {
  display: none !important;
}

.input-range__track--active {
  color: var(--primary_color) !important;
  background-color: var(--primary_color) !important;
}

.input-range__slider {
  background-color: var(--primary_color) !important;
  color: var(--primary_color) !important;
  border: none !important;
}



.preview-bg {
  width: 15rem !important;
  height: 10rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-image: url('../../assets/images/screen-img.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.sm-preview {
  width: 71px;
  height: 32px;
  cursor: pointer;
}
.companydata{
  word-break: break-all;
}