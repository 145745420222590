.login-card {
  width: 564px;
  height: 100%;
  background-color: white;
  margin: 10vh auto;
  padding: 1rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  h2 {
    color: var(--primary_color);
  }
}

// form {
//   max-width: 500px;
//   margin: 0 auto;
// }

// .form {
//   max-width: 400px;
//   margin: 0 auto;
// }

// button[type='submit'],
// input[type='submit'] {
//   background: #ec5990;
//   color: white;
//   text-transform: uppercase;
//   border: none;
//   margin-top: 40px;
//   padding: 20px;
//   font-size: 16px;
//   font-weight: 100;
//   letter-spacing: 10px;
// }

// button[type='submit']:hover,
// input[type='submit']:hover {
//   background: #bf1650;
// }

// button[type='submit']:active,
// input[type='button']:active,
// input[type='submit']:active {
//   transition: 0.3s all;
//   transform: translateY(3px);
//   border: 1px solid transparent;
//   opacity: 0.8;
// }

// input:disabled {
//   opacity: 0.4;
// }

// input[type='button']:hover {
//   transition: 0.3s all;
// }

// button[type='submit'],
// input[type='button'],
// input[type='submit'] {
//   -webkit-appearance: none;
// }
