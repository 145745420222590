.custom-input {
  width: 150%;
  label {
    margin-bottom: 0.7rem;
  }
  input {
    width: 100%;
    height: 48px;
    // background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;
    background: url('../../assets/icons/searchIcon.svg') no-repeat right
      var(--white_background); // background-position-x: 0rem;
    background-position-x: calc(100% - 0.4rem) !important;

    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
}

.custom-dropdown {
  width: 50%;
  select {
    padding: 5px;
    background: url('../../assets/icons/downArrow.svg') no-repeat right
      var(--white_background) !important;
    -webkit-appearance: none;
    background-position-x: calc(100% - 0.4rem) !important;
    width: 100%;
    height: 48px;
    border: 1px solid #cccccc;
    color: var(--grey_two) !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;
    cursor: pointer;
    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
  label {
    margin-bottom: 0.7rem;
  }
}

.plain-input {
  width: 100%;
  label {
    margin-bottom: 0.7rem;
    font-weight: bold;
  }
  input {
    width: 100%;
    height: 48px;
    // background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;

    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
}

.plain-textarea {
  label {
    margin-bottom: 0.7rem;
    font-weight: bold;
  }
  textarea {
    width: 100%;

    // background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;

    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
}

.plain-dropdown {
  label {
    margin-bottom: 0.7rem;
    font-weight: bold;
  }
  select {
    padding: 5px;
    background: url('../../assets/icons/downArrow.svg') no-repeat right
      var(--white_background) !important;
    -webkit-appearance: none;
    background-position-x: calc(100% - 0.4rem) !important;
    width: 100%;
    height: 48px;
    border: 1px solid #cccccc;
    color: var(--grey_two) !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;
    cursor: pointer;
    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
}

.custom-folder:hover {
  background-color: var(--gray_six_color);
  border-radius: 4px;
  img {
    display: block !important;
  }
}
.disabledItem{
  color: white;
  text-decoration: #cccccc;
}
.dropdown-menu.show{
  inset: 22px auto auto -30px !important;
}
.drop_end{
  padding-left:0;
  outline: 0;
  cursor: pointer;
}
.fldr_name{
  position: relative;
  top: -0.5rem;
  max-width: 4rem;
  overflow-wrap: break-word;
}
.all_fldr{
  cursor: pointer;
  width: 5.3rem;
}