.ad-report {
    margin-block: 2%;
    margin-inline: 2%;
    border: 1px solid #cfcfcf;
    box-shadow: 5px 5px 2.5px #dfecdf;
    border-radius: 20px;
    padding: 2%;
}

.counter-set{
    margin-block: 'auto'; height: '100%'
}