

.delete-icon {
    margin-left: 5px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
  
.multi-select {
  border-color: coral;
}
.Multifield{
  box-shadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px";
}
.txt-area{
  width: 100%;
  height: 100%;
  padding: 1%;
  border: 1px solid #afafaf;
  border-radius: 5px;
  
}
.ticket-tags{
  color: black;
  background-color:#e0e0e0 ;
  border-radius: 4px;
}
.cursor-null{
  cursor: no-drop;
}
