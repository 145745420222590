/* .plain-dropdown{
    width: 80% !important;
    margin-inline: auto !important;
} */

 .emoji {
     width: 80%;
     margin-inline: auto;
 }

 .selected-emoji {
     height: 10%;
     margin-block: 3%;
 }

 .mygif{
     width: '80%' !important;
     margin-inline: auto !important ;
     margin-block: 5% !important;
 }

 .txt {
     width: 100%;
     margin-inline: auto;

 }

 .themeName {
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
 }