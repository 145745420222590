.ad-card {
  background-color: var(--white_background);
  padding: 1rem;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.preview-bg {
  display: flex;
  justify-content: center;
  align-items: center;
  // background-image: url('../../assets/images/screen-img.png');
  width: 12rem;
  height: 6.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 4px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: var(--primary_light_color);
  color: #000;
  padding: 10px;
  font-weight: 500;
  border: 2px solid var(--primary_color);
  border-radius: 50px;
  cursor: pointer;
}
.dropdown-toggle:hover{
  background-color: var(--primary_color);
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  // margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  display: none;
  z-index: 1;
}

.dropdown-menu a {
  display: block;
  padding: 10px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
  border-bottom: #afafaf solid 1px;
}
.dropdown-menu a:hover{
  background-color: var(--primary_light_color);
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.reportButton{
  background-color: var(--primary_light_color);
  color: #000;
  padding: 10px;
  font-weight: 500;
  border: 2px solid var(--primary_color);
  border-radius: 20px;
  cursor: pointer;
}
.reportButton:hover{
  background-color: var(--primary_color);
}
.card-bd{
  border-right: 2px solid #CCCCCC;
}
.dropdown-menu{
  position: absolute;
  right: 10px;
}