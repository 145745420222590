@import url('https://fonts.googleapis.com/css2?family=PT+Sans&family=Raleway&display=swap');

body {
  margin: 0 auto;
  padding: 0;
  font-family: 'PT Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa !important;
}

:root {
  --primary_color: #558f55;
  --primary_dark_color: #155215;
  --primary_light_color: #dfecdf;
  --secondary_color: #f4f4f4;
  --secondary_light_color: #f4f4f4;
  --text_color: #000000;
  --text_light_color: #696969;
  --gray_one_color: #333333;
  --gray_two_color: #666666;
  --gray_three_color: #999999;
  --gray_four_color: #aaaaaa;
  --gray_five_color: #cccccc;
  --gray_six_color: #eeeeee;
  --sativa_color: #77ca59;
  --indica_color: #ca8259;
  --blend_color: #ba59ca;
  --hybrid_color: #59afca;
  --thc_color: transparent;
  --cbd_color: transparent;
  --white_background: white;
}

// $primary: #558f55;
// $primary-dark: #155215;
// $primary-light: #dfecdf;
// $text: rgb(31, 31, 31);
// $grey_one: #333333;
// $grey_two: rgb(92, 92, 92);
// $grey-three: rgb(117, 117, 117);
// $grey_four: rgb(144, 144, 144);
// $grey_five: #aaaaaa;
// $white_background: white;

.primary-btn {
  background-color: var(--primary_color);
  color: white;
  font-weight: bold;
  border: none;
  padding: 0.4rem 1.5rem;
  text-align: center;
  border-radius: 4px;
  &:hover {
    background-color: var(--primary_dark_color);
    transition: background-color 300ms linear;
  }
}

.primary-btn-light {
  background-color: var(--primary_light_color);
  color: var(--primary_color);
  font-weight: bold;
  border: none;
  padding: 0.4rem 1.5rem;
  text-align: center;
  border-radius: 4px;
  &:hover {
    background-color: var(--primary_color);
    color: white;
    transition: all 300ms linear;
  }
}

.danger-btn-light {
  background-color: rgb(255, 187, 187);
  color: rgb(189, 50, 50);
  font-weight: bold;
  border: none;
  padding: 0.4rem 1.5rem;
  text-align: center;
  border-radius: 4px;
  &:hover {
    background-color: rgb(189, 50, 50);
    color: rgb(233, 233, 233);
    transition: all 300ms linear;
  }
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.modal-title {
  font-weight: bold !important;
}

a {
  text-decoration: none !important;
  color: var(--primary_color) !important;

  &:hover {
    color: var(--primary_dark_color) !important;
  }
  &:active {
    background-color: var(--primary_light_color) !important;
  }
}

td {
  cursor: pointer;
}
.page-item.active .page-link {
  background-color: var(--primary_color) !important;
  outline: none !important;
  border: none !important;
}

div,
p,
section,
article,
table,
th,
tr,
thead,
h1,
h2,
h3,
h4,
h5,
h6,
button,
span {
  color: var(--text);
}

.ant-modal-content {
  border-radius: 8px !important;
}
.ant-modal-header {
  border-radius: 8px !important;
  border-bottom: 0px !important;
}
.ant-modal-title {
  color: var(--primary_color) !important;
  font-size: 20px !important;
}


.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}
.input-group-text{
  display: flex;
  align-items: center;
}
// .checkbox-container {
//   display: flex;
//   align-items: center;
// }

.checkbox-input {
  margin-right: 20px;
}

.checkbox-label {
  font-size: 20px;
}