.nav-section {
  background-color: white;
}

.custom-navbar {
  max-width: 1440px;
  margin: auto;
  padding: 1rem;
  h2 {
    color: var(--primary_color);
  }
}
