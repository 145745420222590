.custom-input {
  width: 150%;
  label {
    margin-bottom: 0.7rem;
  }
  input {
    width: 100%;
    height: 48px;
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;

    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
}

.custom-dropdown {
  width: 50%;
  select {
    padding: 5px;
    background: url('../../assets/icons/downArrow.svg') no-repeat right
      var(--white_background) !important;
    -webkit-appearance: none;
    background-position-x: calc(100% - 0.4rem) !important;
    width: 100%;
    height: 48px;
    border: 1px solid #cccccc;
    color: var(--grey_two) !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    padding-left: 0.7rem;
    &:focus {
      outline: 1px solid var(--primary_color) !important;
    }
  }
  label {
    margin-bottom: 0.7rem;
  }
}

.dropdown-toggle::before {
  display: none !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  height: 48px;
  border: 1px solid #cccccc;
  border-radius: 4px !important;
}

.ant-select-selector:hover {
  border: 1px solid #cccccc !important;
}
// .page-link{
//   color: white !important;
// }
.table-responsive{
  overflow-x: visible !important;
}