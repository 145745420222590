.css-13cymwt-control {
  height: 48px !important;
}

.css-t3ipsp-control {
  height: 48px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none !important;
}

.css-tj5bde-Svg {
  display: none !important;
}

.css-t3ipsp-control {
  // outline: 1px solid var(--primary_color) !important;

  border-color: 1px solid var(--primary_color) !important;
  box-shadow: 0 0 0 1px var(--primary_color) !important;
  border-width: 0px !important;
}