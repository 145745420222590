.eye-btn {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
  }
  .login-card1 {
    width: 564px;
    height: 100%;
    background-color: white;
    margin: 10vh 70px;
    padding: 1rem;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    h2 {
      color: var(--primary_color);
    }
  }
  
