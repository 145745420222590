.btn-close {
  background-color: var(--grey_five) !important;
}
.modal-title {
  color: var(--primary_color);
}

.upload-btn {
  background: var(--white_background);
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--grey_one);
  padding: 0.5rem 1rem;
  min-width: 12rem;
  transition: all 500ms ease;
  &:hover {
    background-color: var(--grey_five);
    font-weight: bold;
  }
}

.plain-textarea span {
  background-color: var(--primary_light_color);
  padding: 0.4rem 1rem;
  border-radius: 4px;
  position: relative;
  bottom: 4.5rem;
  z-index: 1000;
}

.modal-content {
  border: none !important;
  border-radius: 8px !important;
  outline: none !important;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08) !important;
}
