.custom-color-picker {
  .form-control {
    border-radius: none !important;
  }
  .form-control-color {
    width: 10rem !important;
  }

  .form-label {
    font-weight: bold;
  }
}
