.profile-page {
  margin: 2rem 0;
  .profile-card {
    background: #ffffff;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: start;
  }
}
