.overview_side{
    width: 100%;
    height: 100%;
    background-color: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}
.overview_title{
    border-bottom: 1px solid #CCCCCC;
    color: #333333;
}