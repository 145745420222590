.container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  // background-color: #f5f5f5;
}

.heading {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.fileInputContainer {
  position: relative;
  display: inline-block;
}

.fileInput {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.fileInputLabel {
  display: inline-block;
  background-color: var(--primary_color);
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

// .fileInputLabel:hover {
//   background-color: var(--primary_dark_color);
// }

.imageContainer {
  margin-top: 20px;
}

.subheading {
  font-size: 18px;
  margin-bottom: 10px;
}

.imagesWrapper {
  display: flex;
  flex-wrap: wrap;
}

.imageItem {
  width: 100px;
  height: 100px;
  margin: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.clearButton {
  display: block;
  margin-top: 10px;
  padding: 8px 31px;
  background-color: #f44336;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 6px;
}

.multi-select {

  width: 700px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  &:focus {
    outline: 1px solid var(--primary_color) !important;
  }
}

.css-48ayfv {
  width: 768px !important;
  height:  48px !important;
}

.css-1r4vtzz {
  font-size: 50px;
  width: -webkit-fill-available !important;
  height: 48px !important;
}

.multi-select .dropdown-indicator {
  color: #555;

}

.multi-select .option {
  padding: 8px;
  width: -webkit-fill-available !important;
}

.multi-select .option.is-selected {
  background-color: #007bff;
  color: #fff;
}

.multi-select .option input[type="checkbox"] {
  margin-right: 8px;
}

.multi-select .menu {
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px";
}

.multi-select .menu .menu-list {
  padding: 0;
}

.multi-select .menu .menu-list .menu-item {
  padding: 8px;
}

.multi-select .menu .menu-list .menu-item:hover {
  background-color: #f0f0f0;
}

.plain-dropdown .multi-select .search-bar {
  border-color: 1px solid rgb(4, 117, 4) !important;
}
.Multifield{
  box-shadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px";
}
.css-1gpjby2 svg{
  display: none;
  // width: 90px !important;
  // height: 50px !important;
  // margin-left: 80px !important;
  // padding: 30px 0px !important;
}
.plain-dropdown .multi-select .search-bar :focus{
  border-color: 1px solid rgb(4, 117, 4) !important;
}
.css-6y1x9t-control {
  // outline: 1px solid var(--primary_color) !important;

  border-color: 1px solid var(--primary_color) !important;
  box-shadow: 0 0 0 1px var(--primary_color) !important;
  border-width: 0px !important;
}